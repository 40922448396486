/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    strong: "strong",
    blockquote: "blockquote",
    a: "a",
    ul: "ul",
    li: "li",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Lo prometido es deuda, empezamos con este curso de introducción al control. Antes que nada tengo que pedir perdón por adelantado a los que queráis cursarlo, va a estar en un estado muy alfa, aunque intentaré explicarme lo mejor que pueda. Al lío:"), "\n", React.createElement(_components.h3, null, "¿Qué es la Ingeniería de Control?"), "\n", React.createElement(_components.p, null, "La rama de la ingeniería que estudia como hacer que ", React.createElement(_components.strong, null, "los sistemas se comporten de una forma predeterminada"), ". Cuando hablamos de sistema me gusta especialmente la definición de Wikipedia, por lo genérico de la misma:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Un ", React.createElement(_components.strong, null, "sistema"), " es un ", React.createElement(_components.a, {
    href: "http://es.wikipedia.org/wiki/Conjunto",
    title: "Conjunto"
  }, "objeto complejo"), " cuyos componentes se relacionan con al menos algún otro componente; puede ser ", React.createElement(_components.a, {
    href: "http://es.wikipedia.org/wiki/Materia",
    title: "Materia"
  }, "material"), " o ", React.createElement(_components.a, {
    href: "http://es.wikipedia.org/wiki/Concepto",
    title: "Concepto"
  }, "conceptual"), "."), "\n"), "\n", React.createElement(_components.p, null, "Es decir que podemos aplicar la ingeniería de control sobre cualquier conjunto de elementos relacionados. Por otra parte, para aclarar otro concepto, se llama ", React.createElement(_components.strong, null, "Ingeniería de Sistemas"), " a la comprensión mediante herramientas matemáticas de estos sistemas."), "\n", React.createElement(_components.p, null, "Podemos encontrar aplicaciones de Control en sistemas de tan diversos tipos como:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Eléctricos"), "\n", React.createElement(_components.li, null, "Mecánicos"), "\n", React.createElement(_components.li, null, "Biológicos"), "\n", React.createElement(_components.li, null, "Químicos"), "\n", React.createElement(_components.li, null, "Económicos"), "\n", React.createElement(_components.li, null, "Informáticos"), "\n"), "\n", React.createElement(_components.p, null, "De ahí la versatilidad del uso del control, que comenzó con el auge de la electrónica pero una vez llegado a su madurez con los sistemas de control digitales se independizó de esa rama e incluso de su aplicación en industria."), "\n", React.createElement(_components.h3, null, "Representación"), "\n", React.createElement(_components.p, null, "Para representar los sistemas de una forma gráfica utilizaremos los diagramas de bloques, como vemos a continuación, el convenio más extendido es el de llamar a las entradas de un sistema a controlar ‘u’ y a las salidas ‘y’, con un numero identificador."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox.entramado.net/wp-content/uploads/sites/6/Sistema1.png"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 300px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 41.139240506329116%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA30lEQVQoz52Q2YrEMAwE/f9fF8hT3nLfCbnvREMZHGbDziysoJGRrVLLKgxDmaZJ+r6XYRh0HsdR5nn+Kt4a0UMtiiJRTdPIM47jkK7r7sdPUf8tYKk8z6UoCmnb9pbjODrjHK3rqh0Y50mS6MxgM3zbNqnrWlSWZeL7vpABp2kqlmXJeZ63W4a+uyrLUvgq6ggDQRBoEwpIHMdinBogIOK6LlmWRfZ9/wGsqko7QzimplfmgiYsI0AGzB0PWcWcEQAz8D24U58++D8BS9m2rfd3XVc8z/tT/PezRi8MWC/jkGRzI9JH6AAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"sistema de 3 entradas y 2 salidas\"\n        title=\"sistema de 3 entradas y 2 salidas\"\n        src=\"/static/785b1330185d5ccdb53e27713cf3c771/5a46d/Sistema1-300x124.png\"\n        srcset=\"/static/785b1330185d5ccdb53e27713cf3c771/c26ae/Sistema1-300x124.png 158w,\n/static/785b1330185d5ccdb53e27713cf3c771/5a46d/Sistema1-300x124.png 300w\"\n        sizes=\"(max-width: 300px) 100vw, 300px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "Como podemos observar en este sistema tenemos acceso a 3 entradas para modificar su comportamiento y 2 salidas que podemos medir."), "\n", React.createElement(_components.p, null, "Pero en este curso no vamos a irnos a sistemas tan complejos, sólo vamos a trabajar con sistemas SISO, una entrada y una salida. Algo como esto:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox.entramado.net/wp-content/uploads/sites/6/Sistema2.png"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 300px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 41.139240506329116%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA0klEQVQoz52Qx4rEQAxE+/9/zxcfnW2Mc85anqDNzE5gWUHRQqGq1CaOY5nnWYZhuDFNk9a+4XEeUIPLNE0jv2NZFum6Tgf7vn8B9XcBlynL8m2jrmtVxe26rpqP46hvlmVyHMfLHlwmz3NdtqDouu69wMvMo6uiKLSGMEiSREXathVTVZWc56ln4mTbNknTVF/iui7t7fv+5AQBZqx7cgzdJ0MK7Mko0sMNojYHCNoLEATWufn0wf8JuIzjOBJFkXieJ77vPyEIgj/V2A3DUOD6Aa8NaI0+He/oAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"sistema SISO\"\n        title=\"sistema SISO\"\n        src=\"/static/fb947e180abd0efa0c0265bff41add13/5a46d/Sistema2-300x124.png\"\n        srcset=\"/static/fb947e180abd0efa0c0265bff41add13/c26ae/Sistema2-300x124.png 158w,\n/static/fb947e180abd0efa0c0265bff41add13/5a46d/Sistema2-300x124.png 300w\"\n        sizes=\"(max-width: 300px) 100vw, 300px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.h3, null, "Los sistemas dinámicos"), "\n", React.createElement(_components.p, null, "Los sistemas que vamos a estudiar se llaman ", React.createElement(_components.strong, null, "sistemas dinámicos"), " puesto que sus salidas dependen de entradas y/o salidas anteriores. Como en el siguiente ejemplo, se trata de un histórico de las ", React.createElement(_components.a, {
    href: "http://www.eltiempo.es/valencia.html?v=historico"
  }, "temperaturas medias durante el último mes en València"), ". La predicción del tiempo implica un sistema extremadamente complejo que estudia la ingeniería de sistemas en combinación con otras áreas como al meteorología y la física."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 300px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/e998efcb4649c4ca446b139bbc070658/5a46d/Temperatura_media_valencia-300x185.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 61.39240506329114%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABW0lEQVQoz3VTWa6DMAzk/kfsIvWjlKWCkNcApYkzT5MFtdAiWXEcZzwTm8Jai2VZwE9E4L1fbbv3wOf+ixXP5xNa6xWQhnQx+yFOR2vI6RTOtjkroFIKwzCEoHMuGJO++b4s4ZSC1HWMzzMcizEvKSimaVoZrhdT5eBz5ZlS8E0TfLnf4a9XyOUCMSawl3mGtxYF2e0YptVZG4GthSNAZsuCzB/HGB8GSFVFycaYPUNW5D7JEMrs++DnZ/DpXaVp4Os6NoySPwCT+baN7zSOIVkOh8CaDFZAsiSBBLQ2hXJVkmzbFq7rIpgxcFUFeTzgXq/fzeKzvBUpJmPwl8eGTI5H+GWJklN1SZ3cMUxj9jGHQ9dBn88AO6hUBEkArO7fx2YDuJ2KALhYC12WQNftu/xDZmb2vs/DHppyvd2gtEZVVcH6vkfbtqjrGhx8rk3TBJ/n9JmT4zmfv/A/7amqwzfT6T8AAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Evolución de la temperatura media con respecto al tiempo.\"\n        title=\"images/restador_operacional-300x125.jpg\"\n        src=\"/static/e998efcb4649c4ca446b139bbc070658/5a46d/Temperatura_media_valencia-300x185.png\"\n        srcset=\"/static/e998efcb4649c4ca446b139bbc070658/c26ae/Temperatura_media_valencia-300x185.png 158w,\n/static/e998efcb4649c4ca446b139bbc070658/5a46d/Temperatura_media_valencia-300x185.png 300w\"\n        sizes=\"(max-width: 300px) 100vw, 300px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Este es un ejemplo práctico del tipo de gráfica a los que nos vamos a tener que acostumbrar en este curso, la evolución de una señal en el tiempo."), "\n", React.createElement(_components.p, null, "Una vez introducidos los conceptos principales podemos pasar a algo con más chicha, distintas estrategias en Ingeniería de Sistemas ¿Como lleváis el álgebra?"), "\n", React.createElement(_components.p, null, "Espero vuestros comentarios y dudas para mejorar el curso."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
